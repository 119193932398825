import Api from '@/services/Api'

export default {
    getAllAssociations() { return Api().get('associationsResults') },
    getAllArchivedAssociations() { return Api().get('archivedAssociationsResults') },
    getAllPeople() { return Api().get('peopleResults') },
    addNewAssociation(payload) { return Api().post('associationsAdd', payload) },
    addNewAssessment(payload) { return Api().post('assessmentsAdd', payload) },
    addNewPerson(payload) { return Api().post('peopleAdd', payload) },
    deleteAssociation(payload) { return Api().post('deleteAssociation', payload) },
    editAssociation(payload) { return Api().post('editAssociation', payload) },
    getAssociationByID(payload) { return Api().get(`getAssociationByID/${payload.association_id}`) },
    searchAssociationByName(payload) { return Api().get('searchAssociationByName', payload) }

}