<template>
    <v-container v-if="$store.state.user">

        <!-- Page Title-->
        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h3 class="display-2 font-weight-bold mb-3">Add Reoccurring Payment</h3>
                    <p>
                        <b>Note:</b> Changes made here are permanent and cannot be easily undone.  
                        If a mistake is made, you will have to remove each reoccurring Assessment/Payment
                        charged to this person individually.
                    </p>
            </v-col>   
        </v-row>


        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">
                <v-form ref="form" v-model="valid" lazy-validation>
                    
                    <!-- Payment Type Dropdown -->
                    <v-select v-model="selectedRType" :items="typeNames" label="Payment Type" solo></v-select>

                    <!-- Frequency Dropdown -->
                    <!-- :items is determined by which selection is chosen from the above Payment Type dropdown -->
                    <v-select v-model="selectedFrequency" item-text="type" item-value="id" label="Frequency" solo 
                        :items="selectedRType == 'Assessment' || 'Special Assessment' ? assessmentTypes: paymentTypes"></v-select>


                    <!-- Amount Text Field -->
                    <v-text-field :rules="amountRules" label="Amount" prepend-icon="mdi-currency-usd" 
                        v-model="amount" required></v-text-field>

                    <!-- Start Date Calendar Picker -->
                    <v-menu
                        ref="menuStart" v-model="menuStart"
                        :close-on-content-click="false"
                        :return-value.sync="start_date"
                        transition="scale-transition"
                        offset-y min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="start_date" label="Start Date" prepend-icon="mdi-calendar"
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>

                        <v-date-picker v-model="start_date" no-title scrollable>
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" @click="$refs.menuStart.save(start_date)">OK</v-btn>
                        <v-btn test color="primary" @click="menuStart = false">Cancel</v-btn>

                        </v-date-picker>
                    </v-menu>

                    <!-- End Date Calendar Picker -->
                    <v-menu
                        ref="menuEnd" v-model="menuEnd"
                        :close-on-content-click="false"
                        :return-value.sync="end_date"
                        transition="scale-transition"
                        offset-y min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="end_date" label="End Date"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>

                        <v-date-picker v-model="end_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="$refs.menuEnd.save(end_date)">OK</v-btn>
                            <v-btn text color="primary" @click="menuEnd = false">Cancel</v-btn>
                        </v-date-picker>
                    </v-menu>

                    <!-- Submit and Validate Buttons -->
                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Submit</v-btn>
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>
                </v-form>
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
    import PeopleService from '@/services/PeopleService'
    import AssociationsService from '../../services/AssociationsService'
    // import PaymentService from '@/services/PaymentService'

    export default {
        name: 'addReoccurring',

        data () {
            return {
                valid: false,
                typeNames: ["Assessment", "Special Assessment", "Payment", "Late Charge"],
                selectedRType: "Assessment",
                assessmentTypes: [
                    {id: 1, type: "Monthly"},
                    {id: 5, type: "quarterly"},
                    {id: 6, type: "Semi-Annually"},
                    {id: 7, type: "Annually"}
                ],
                paymentTypes: [{id: 1, type: "Monthly"}],
                selectedFrequency: 1,
                amount: null,
                amountRules: [
                    v => !!v || 'Amount is required',
                    v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Can only contain numerals and 1 period.',
                ],

                // **TODO** : Custom rules to ensure end_date is not before start_date
                // Variables below used for the date picker Vuetify components
                start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menuStart: false,
                menuEnd: false,
            }
        },

        mounted() {
            this.$refs.form.validate()
        },

        computed: {
            dateRangeText () {
                return this.dates.join(' ~ ')
            }
        },

        methods: {
            validate () {

                // Validate user entry against custom rules created above in data.
                this.$refs.form.validate()

                let entry = {
                    assessment_type_id: this.selectedFrequency,
                    payment_type_id: this.selectedFrequency,
                    date: date,
                    note: "",
                    amount: this.amount,
                    person_id: this.$store.state.personID
                }

                // Late Charges are simply another type of Assessment, so a Reoccurring Late Charge is in
                //     essence just another Reocurring Assessment of type 'Administrative Late Fee. In the
                //     database, that translates to assessement_type_id 13.
                if (this.selectedRType == 'Late Charge') {
                    this.selectedRType = 'Assessment'
                    entry.assessment_type_id = 3
                }

                if (this.selectedRType == 'Special Assessment') {
                    this.selectedRType = 'Assessment'
                    entry.assessment_type_id = 10
                }

                // date is a counter, and while it is less than end_date we know that it has to repeat.
                // Also, due to weird time zone reasons, the year, day, and month have to be explicitly set
                //     in the way shown below, where month is 0-indexed.
                let date = new Date()
                const [year, month, day] = this.start_date.split('-')
                date.setFullYear(year)
                date.setMonth(month - 1)
                date.setDate(day)

                while (this.convertToISOString(date) <= this.end_date) {
                    entry.date = this.convertToISOString(date)

                    // If the type is supposed to be an assessment, create a new entry with the parameters
                    //     and add it to the Assessment table.
                    if (this.selectedRType == 'Assessment' || this.selectedRType == "Special Assessment") {
                        AssociationsService.addNewAssessment(entry)
                    }

                    // Else it is supposed to be a payment, so create the entry in the Payment table.
                    else {
                        PeopleService.sendAddPayment(entry)
                    }


                    // Increment the month field depending on the selected frequency.
                    switch (this.selectedFrequency) {
                        case 1:
                            date.setMonth(date.getMonth() + 1, day)
                            break
                        case 5:
                            date.setMonth(date.getMonth() + 3, day)
                            break
                        case 6:
                            date.setMonth(date.getMonth() + 8, day)
                            break
                        case 7:
                            date.setMonth(date.getMonth() + 12, day)
                            break
                    }
                    
                    // If the date is changed to a time beyond the current month (ex. February 31st), by 
                    //     default the day will get rolled over into the next month (↑ becomes March 3rd).
                    // To fix this, the date is set to the last day of the previous month.
                    if (date.getDate() != day) {
                        date.setDate(0)
                    }
                }

                // Move user back to page with list of people (based on selected association)
                this.$router.push({ path: `/associations/peopleDetails/${this.$store.state.personID}`})
            },


            // The normal Date.toISOString() method won't work because of potential ambiguity in the month
            //     and day. For example, the date could be 2021-1-1 or 2021-01-01. As such,
            //     the trickery below must be used to consruct the ISO into the form wanted.
            // date is the Date object that we want to convert into ISO format.
            convertToISOString(date) {

                // Year is fine as is
                const year = date.getFullYear()

                // getMonth() returns the month as a 0-indexed integer, so it must be incremented by 1.
                // Then, an empty string is appended to it to convert it into a String.
                // This is because Strings have the padStart() method, which takes in two arguments.
                // The first argument determines how long the string should be, and the second pads the 
                //     front of the string with the argument.
                // The current form adds a '0' in case the month is a single digit.
                const month = ((date.getMonth() + 1) + '').padStart(2, '0')

                // Same as with the month, except getDate() is not 0-indexed and does not need incrementing
                const day = (date.getDate() + '').padStart(2, '0')

                // Pass the above values into a string and return it.
                return `${year}-${month}-${day}`
            },

            // Reset form -- remove all user input currently displaying on form
            reset () {
                this.$refs.form.reset()
            },
        }
    }
</script>